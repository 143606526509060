import type { DynamicHelpConfig } from '../../default/cms/dynamicHelp.types';

/**
   * const emailMappingLascanaNL = {
      '0': 'klantenservice@lascana.nl',
      '1': 'bo_aftersales@ottobv.nl',
      '2': 'debiteuren@ottobv.nl',
      '3': 'bo_orderacceptatie@ottobv.nl',
      '4': 'orderacceptatie@ottobv.nl',
      '5': 'sander.trum@ottobv.nl',
      '6': 'solliciteer@ottobv.nl',
    };
  */

export const dynamicHelp: DynamicHelpConfig = {
  labelMessageKey: 'forms.contact.dynamicSubject.howCanWeHelp',
  items: [
    {
      messageKeySelectOption: 'forms.contact.dynamicSubject.order',
      subHelpTopics: [
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.orderPlacement',
          subHelpTopics: [
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.orderInsideNL',
              code: '%%LBESBP111%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.orderInsideNL',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.orderOutsideNL',
              code: '%%LAFTLE112%%',
              targetEmailID: '1',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.orderOutsideNL',
            },
          ],
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.orderChange',
          code: '%%LBESBW121%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.orderChange',
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.orderCancel',
          code: '%%LBESBW131%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.orderCancel',
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.orderStatus',
          code: '%%LOV%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.orderStatus',
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.productInfo',

          code: '%%LBESAR152%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.productQuestion',
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.otherQuestion',
          code: '%%LOV%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.otherQuestion',
        },
      ],
    },

    {
      messageKeySelectOption: 'forms.contact.dynamicSubject.shipping',
      subHelpTopics: [
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.orderStatus',
          code: '%%LOV%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.orderStatus2',
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.deliveryOrderNotReceived',
          code: '%%LBEZSB221%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.deliveryOrderNotReceived',
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.deliveryWrongArticle',
          code: '%%LBEZLO231%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.deliveryWrongArticle',
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.deliveryDamagedArticle',
          code: '%%LBEZPA241%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.deliveryDamagedArticle',
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.deliveryAddress',
          code: '%%LBEZAL251%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.deliveryAddress',
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.otherQuestion',
          code: '%%LOV%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.otherQuestion',
        },
      ],
    },

    {
      messageKeySelectOption: 'forms.contact.dynamicSubject.returnsAndExchanges',
      subHelpTopics: [
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.exchanges',
          code: '%%LRERVR311%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.exchanges',
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.returnsInstructions',
          code: '%%LRERVR321%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.returnsSmallProducts',
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.returnsStatus',
          subHelpTopics: [
            {
              messageKeySelectOption:
                'forms.contact.dynamicSubject.returnsStatusSentLessThan2WeeksAgo',
              code: '%%LRERSR331%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.returnsStatusSentLessThan2WeeksAgo',
            },
            {
              messageKeySelectOption:
                'forms.contact.dynamicSubject.returnsStatusSentMoreThan2WeeksAgo',
              code: '%%LAFTRE332%%',
              targetEmailID: '1',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.returnsStatusSentMoreThan2WeeksAgo',
            },
          ],
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.otherQuestion',
          code: '%%LOV%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.otherQuestion',
        },
      ],
    },
    {
      messageKeySelectOption: 'forms.contact.dynamicSubject.paymentAndMoney',
      subHelpTopics: [
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.myPayments',
          subHelpTopics: [
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.paymentOverview',
              code: '%%LBRVVR411%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.paymentOverview',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.customerAccount',
              code: '%%LBRVVS412%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.customerAccount',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.invoiceOrBalance',
              code: '%%LBRVVS413%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.invoiceOrBalance',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.refunds',
              code: '%%LBRVTE414%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.refunds',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.paymentReminder',
              code: '%%LBRVOF415%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.paymentReminder',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.directDebit',
              subHelpTopics: [
                {
                  messageKeySelectOption: 'forms.contact.dynamicSubject.directDebitRequest',
                  code: '%%LBRVVR416%%',
                  targetEmailID: '1',
                  messageKeyAnswer: 'forms.contact.dynamicAnswer.directDebitRequest',
                },
                {
                  messageKeySelectOption: 'forms.contact.dynamicSubject.directDebitChange',
                  code: '%%LDEBBE417%%',
                  targetEmailID: '2',
                  messageKeyAnswer: 'forms.contact.dynamicAnswer.directDebitChange',
                },
              ],
            },
          ],
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.paymentOptions',
          subHelpTopics: [
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.payingByInstallment',
              subHelpTopics: [
                {
                  messageKeySelectOption:
                    'forms.contact.dynamicSubject.payingByInstallmentRequestMoney',
                  code: '%%LORDAE421%%',
                  targetEmailID: '3',
                  messageKeyAnswer: 'forms.contact.dynamicAnswer.creditRequestMoney',
                },
                {
                  messageKeySelectOption: 'forms.contact.dynamicSubject.payingByInstallmentAskBRK',
                  code: '%%LORDBK422%%',
                  targetEmailID: '4',
                  messageKeyAnswer: 'forms.contact.dynamicAnswer.askBRK',
                },
                {
                  messageKeySelectOption:
                    'forms.contact.dynamicSubject.payingByInstallmentRequestPaymentArrangement',
                  code: '%%LDEBBE423%%',
                  targetEmailID: '2',
                  messageKeyAnswer: 'forms.contact.dynamicAnswer.paymentArrangement',
                },
                {
                  messageKeySelectOption:
                    'forms.contact.dynamicSubject.payingByInstallmentQuestionAboutCreditArrangement',
                  code: '%%LORDKR424%%',
                  targetEmailID: '3',
                  messageKeyAnswer: 'forms.contact.dynamicAnswer.questionAboutCreditArrangement',
                },
              ],
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.paymentOptionsIDEAL',
              code: '%%LBRVBE431%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.paymentOptionsIDEAL',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.paymentOptionsAcceptgiro',
              code: '%%LBRVBE432%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.paymentOptionsAcceptgiro',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.paymentOptionsOnlineBanking',
              code: '%%LBRVBE433%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.paymentOptionsOnlineBanking',
            },
          ],
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.credit',
          subHelpTopics: [
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.creditPrepay',
              code: '%%LBRVBE433%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.creditPrepay',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.creditBorrowExtraMoney',
              code: '%%LORDAE442%%',
              targetEmailID: '3',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.creditRequestMoney',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.creditBKR',
              code: '%%LORDBK443%%',
              targetEmailID: '4',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.askBRK',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.creditPaymentScheme',
              code: '%%LDEBBE444%%',
              targetEmailID: '2',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.paymentArrangement',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.creditArrangement',
              code: '%%LORDKR445%%',
              targetEmailID: '3',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.questionAboutCreditArrangement',
            },
            {
              messageKeySelectOption:
                'forms.contact.dynamicSubject.creditRequestOrChangeDirectDebit',
              code: '%%LBRVVR446%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.creditRequestOrChangeDirectDebit',
            },
          ],
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.otherQuestion',
          code: '%%LOV%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.otherQuestion',
        },
      ],
    },
    {
      messageKeySelectOption: 'forms.contact.dynamicSubject.promotionsAndDiscounts',
      subHelpTopics: [
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.promotionCodeNotWorking',
          code: '%%LAVKPR611%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.promotionCodeNotWorking',
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.otherQuestion',
          code: '%%LOV%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.otherQuestion',
        },
      ],
    },
    {
      messageKeySelectOption: 'forms.contact.dynamicSubject.warrantyAndRepair',
      subHelpTopics: [
        {
          messageKeySelectOption:
            'forms.contact.dynamicSubject.warrantyAndRepairArticleDefectiveAfterUse',
          code: '%%LPGFPA811%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.warrantyAndRepairWarrantyConditions',
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.warrantyAndRepairArticleDefective',
          code: '%%LPGFPA821%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.warrantyAndRepairArticleDefective',
        },
        {
          messageKeySelectOption:
            'forms.contact.dynamicSubject.warrantyAndRepairWarrantyConditions',
          code: '%%LPGFGA831%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.warrantyAndRepairWarrantyConditions',
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.otherQuestion',
          code: '%%LOV%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.otherQuestion',
        },
      ],
    },
    {
      messageKeySelectOption: 'forms.contact.dynamicSubject.myOTTOAndOtherQuestions',
      subHelpTopics: [
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.website',
          subHelpTopics: [
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.websiteNotWorking',
              code: '%%LAVKST911%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.websiteNotWorking',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.websiteLoginNotPossible',
              code: '%%LAVKIN912%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.websiteLoginNotPossible',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.websiteIncorrectInformation',
              code: '%%LAVKST913%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.websiteIncorrectInformation',
            },
          ],
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.productQuestion',
          code: '%%LBESAR921%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.productQuestionDetailed',
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.noMoreMail',
          code: '%%LBESAR921%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.noMoreMail',
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.changeData',
          subHelpTopics: [
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.changeDataAddressOrPhone',
              code: '%%LAVKKL941%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.changeDataAddressOrPhone',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.changeDataPasswordOrIBAN',
              code: '%%LAVKKL942%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.changeDataPasswordOrIBAN',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.changeDataEmailOrPhone',
              code: '%%LAVKKL943%%',
              targetEmailID: '0',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.changeDataEmailOrPhone',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.birthday',
              code: '%%LORDWK944%%',
              targetEmailID: '3',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.changeDataBirthday',
            },
            {
              messageKeySelectOption: 'forms.contact.dynamicSubject.removeData',
              code: '%%LORDKR945%%',
              targetEmailID: '3',
              messageKeyAnswer: 'forms.contact.dynamicAnswer.removeData',
            },
          ],
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.customerAccountExplanation',
          code: '%%LBRVVS951%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.customerAccountExplanation',
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.otherQuestion',
          code: '%%LOV%%',
          targetEmailID: '0',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.otherQuestion',
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.pressAndMedia',
          code: '%%LBRVVS951%%',
          targetEmailID: '5',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.pressAndMedia',
        },
        {
          messageKeySelectOption: 'forms.contact.dynamicSubject.jobsApplicationsAndVacancies',
          code: '%%L00%%',
          targetEmailID: '6',
          messageKeyAnswer: 'forms.contact.dynamicAnswer.jobsApplicationsAndVacancies',
        },
        // this will not go live until march
        // {
        //   messageKeySelectOption: 'forms.contact.dynamicSubject.reportBarrier',
        //   code: '???',
        //   targetEmailID: '0',
        //   messageKeyAnswer: 'forms.contact.dynamicAnswer.reportBarrier',
        // },
      ],
    },
  ],
};
