import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgCart32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M26.49 10.614a.712.712 0 0 0-.724-.617h-3.978V7.726c0-2.855-2.605-5.191-5.789-5.191s-5.795 2.336-5.795 5.191v2.271h-3.98a.713.713 0 0 0-.724.617L4.056 28.782c0 .179.064.351.181.487a.725.725 0 0 0 .543.194h22.433a.784.784 0 0 0 .543-.194.53.53 0 0 0 .181-.487l-1.448-18.168zM11.654 7.726c0-2.141 1.954-3.893 4.342-3.893s4.342 1.752 4.342 3.893v2.271h-8.683zM5.54 28.166l1.339-16.871h3.326v4.217a.728.728 0 0 0 1.447 0v-4.217h8.687v4.217a.728.728 0 0 0 1.447 0v-4.217h3.293l1.339 16.871z" /></SvgIcon>;
};
const Memo = memo(SvgCart32);
export default Memo;